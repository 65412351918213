import { gsap, Power3 } from "gsap";

import { forEach } from "vendor/common/Helpers";

export default class Menu {
    constructor(args = {}) {
        this.scrollbar = args.scrollbar[0] || null;
        this._initialise();
    }

    // prettier-ignore
    _initialise() {

        this.openButton = document.querySelector(".js-menu-open-button");
        this.overlay = document.querySelector(".js-menu-overlay");

        this.background = document.querySelector(".js-menu-background");

        this.menuItems = document.querySelectorAll(".js-menu-item");

        this.header = document.querySelector(".js-header");

        this._initMenuTimeline();
        this._setHamburgerEvents();
        this._setMenuHandlers();
        
        this.close = () => {
            this._close();
        };
    }

    _initMenuTimeline() {
        const that = this;

        this.timelineFrame = gsap.timeline({
            paused: true,
            onReverseComplete: function() {
                that.overlay.classList.add("is-hidden");
            },
        });

        gsap.set(that.background, { opacity: 0 });
        this.timelineFrame.to(that.background, {
            duration: 0.45,
            opacity: 1,
            ease: "power3.inout",
        });
    }

    _setHamburgerEvents() {
        let menuOpen = false;
        this.openButton.addEventListener("click", e => {
            e.preventDefault();
            if (!menuOpen) {
                this.overlay.classList.remove("is-hidden");
                this.timelineFrame.play();
                this.openButton.classList.add("is-active");
                this._animateItemsIn(450);
            } else {
                this._close();
                this.openButton.classList.remove("is-active");
            }
            menuOpen = !menuOpen;
        });
    }

    _animateItemsIn(delay) {
        let timeDelay = 0;
        forEach(this.menuItems, (i, item) => {
            gsap.set(item, { y: 60, opacity: 0 });
        });
        setTimeout(() => {
            this.header.classList.add("is-menu-open");

            forEach(this.menuItems, (i, item) => {
                gsap.to(item, {
                    duration: 0.8,
                    opacity: 1,
                    y: 0,
                    ease: "power3.inout",
                    delay: timeDelay,
                });

                timeDelay = timeDelay + 0.04;
            });
        }, delay);
    }

    _animateItemsOut() {
        let timeDelay = this.menuItems.length * 0.1;
        forEach(this.menuItems, (i, item) => {
            gsap.to(item, {
                duration: 0.4,
                opacity: 0,
                y: 60,
                ease: "power3.inout",
                delay: timeDelay,
            });

            timeDelay = timeDelay - 0.04;
        });
    }

    _setMenuHandlers() {
        const that = this;
        forEach(this.menuItems, (i, item) => {
            item.addEventListener("click", function(e){
                e.preventDefault();
                const container = document.querySelector(
                    item.getAttribute("href")
                );
                const containerOffset = offset(container);
                that._close();
                that.openButton.classList.remove("is-active");
                if(that.scrollbar != null) {
                    setTimeout(function() {
                        that.scrollbar.scrollTo(
                            1,
                            containerOffset.top + that.scrollbar.scrollTop,
                            900
                        );
                    }, 800);
                }
            })
        });

        function offset(el) {
            var rect = el.getBoundingClientRect(),
                scrollLeft =
                    window.pageXOffset || document.documentElement.scrollLeft,
                scrollTop =
                    window.pageYOffset || document.documentElement.scrollTop;
            return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
        }
    }

    _close() {
        setTimeout(() => {
            this.timelineFrame.reverse();
            this.header.classList.remove("is-menu-open");
        }, 700);
        this._animateItemsOut();
    }
}
