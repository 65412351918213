// -----------------------------------------------------------------------------
// Helpers
// -----------------------------------------------------------------------------

export const version = "2.0";

// const win = window;

// export function parallaxOnMouseMove(el, duration, resistance, mouse) {
//     const $el = $(el);
//     TweenMax.to($el, duration, {
//         x: -((mouse.clientX - win.innerWidth / 2) / resistance),
//         y: -((mouse.clientY - win.innerHeight / 2) / resistance),
//     });
// }

export function getScrollBarWidth() {
    const outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.width = "100px";
    outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

    document.body.appendChild(outer);

    const widthNoScroll = outer.offsetWidth;
    // force scrollbars
    outer.style.overflow = "scroll";

    // add innerdiv
    const inner = document.createElement("div");
    inner.style.width = "100%";
    outer.appendChild(inner);

    const widthWithScroll = inner.offsetWidth;

    // remove divs
    outer.parentNode.removeChild(outer);

    return widthNoScroll - widthWithScroll;
}

export function isScrolledInView(el, offset) {
    const w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName("body")[0];
    let o = offset;

    const windowWidth = w.innerWidth || e.clientWidth || g.clientWidth;
    const windowHeight = w.innerHeight || e.clientHeight || g.clientHeight;

    if (o == null) {
        o = 0;
    }

    const viewport = {
        top: getScrollTop(),
        left: getScrollLeft(),
    };
    viewport.right = viewport.left + windowWidth;
    viewport.bottom = viewport.top + windowHeight;

    const bounds = el.offset();
    bounds.right = bounds.left + el.outerWidth();
    bounds.bottom = bounds.top + el.outerHeight();

    return !(
        viewport.right < bounds.left ||
        viewport.left > bounds.right ||
        viewport.bottom - offset < bounds.top ||
        viewport.top + offset > bounds.bottom
    );
}

// export function isTouchDevice() {
//     return (
//         true ==
//         ("ontouchstart" in win ||
//             (win.DocumentTouch && document instanceof DocumentTouch))
//     );
// }

export function isMobileDevice() {
    return (
        true ==
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        )
    );
}

export function isOldIE() {
    var versions = {};
    var agent = navigator.userAgent;
    var reg = /MSIE\s?(\d+)(?:\.(\d+))?/i;
    var matches = agent.match(reg);
    if (matches != null) {
        versions = { major: matches[1], minor: matches[2] };
    } else {
        return false;
    }

    return versions.major <= 10;
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate) {
    let timeout;
    return function() {
        const context = this,
            args = arguments;
        const later = () => {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

// Safely loop your queries. Works in all browsers.
export function forEach(array, callback, scope) {
    for (let i = 0; i < array.length; i++) {
        callback.call(scope, i, array[i]);
    }
}

export function getOffset(el) {
    const de = document.documentElement;
    const box = el.getBoundingClientRect();
    const top = box.top + window.pageYOffset - de.clientTop;
    const left = box.left + window.pageXOffset - de.clientLeft;
    return { top: top, left: left };
}

export function getScrollTop() {
    return Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
    );
}

export function getScrollLeft() {
    return Math.max(
        window.pageXOffset,
        document.documentElement.scrollLeft,
        document.body.scrollLeft
    );
}

export function getWindowWidth() {
    const w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName("body")[0];

    return w.innerWidth || e.clientWidth || g.clientWidth;
}

export function getWindowHeight() {
    const w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName("body")[0];

    return w.innerHeight || e.clientHeight || g.clientHeight;
}

export function getScreenWidth() {
    return getWindowWidth + getScrollBarWidth();
}
