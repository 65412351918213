// eslint-disable-next-line
import { gsap, Power1, Power2, Power3, Expo } from "gsap";
import { forEach, isMobileDevice } from "vendor/common/Helpers";

export default class MemberNameSlider {
    constructor(args = {}) {
        this.container = args.container;
        this.slides = args.slides;
        this.slideCount = this.slides.length;
        this.currentSlide = 0;
        this.slideHeight = null;
        this.initialHeight = this.slides[0].clientHeight;

        this.sliderNavElement = args.sliderNavElement;

        this.prevBtn = args.prevBtn;
        this.nextBtn = args.nextBtn;

        this.initialX = null;
        this.initialY = null;

        this.zIndex = this.slideCount * 50;

        this.playInterval = null;

        this.duration = args.duration;

        this._initialise();

        gsap.ticker.fps(30);
        //gsap.ticker.lagSmoothing(1000, 16);
    }

    _initialise() {
        this.slides[0].classList.add("active"); //on load, activate the first slide

        var zIndex = this.zIndex;
        forEach(this.slides, (i, slide) => {
            zIndex--;
            slide.style.zIndex = zIndex;
        });

        this._playSlider();

        this._initHandlers();
    }

    _playSlider() {
        this.playInterval = setInterval(() => {
            this._moveToSlide(this.currentSlide, this.currentSlide + 1);
        }, 8000);
    }

    _initHandlers() {
        const self = this;
        if (this.sliderNavElement) {
            this.prevBtn.addEventListener(
                "click",
                this._prevSlide.bind(this),
                false
            );
            this.nextBtn.addEventListener(
                "click",
                this._nextSlide.bind(this),
                false
            );

            //Pause interval on hover
            this.sliderNavElement.addEventListener("mouseenter", function() {
                clearInterval(self.playInterval);
            });
            this.sliderNavElement.addEventListener("mouseleave", function() {
                self._playSlider();
            });
        }

        this.container.addEventListener(
            "touchstart",
            this._startTouch.bind(this),
            false
        );
        this.container.addEventListener(
            "touchmove",
            this._moveTouch.bind(this),
            false
        );

        document.body.addEventListener(
            "keydown",
            e => {
                const code = e.keyCode;
                if (code == 39) {
                    this._nextSlide();
                }
            },
            false
        );

        document.body.addEventListener(
            "keydown",
            e => {
                const code = e.keyCode;
                if (code == 37) {
                    this._prevSlide();
                }
            },
            false
        );
    }

    _moveToSlide(oldIndex, newIndex) {
        const self = this;
        const newSlide = (newIndex + this.slideCount) % this.slideCount;

        if (this.isMovingToSlide) return;
        this.isMovingToSlide = true;

        self.zIndex = self.zIndex + 3;

        this.slides[oldIndex].classList.remove("active");
        this.currentSlide = (newIndex + this.slideCount) % this.slideCount;

        this.slides[newSlide].classList.add("active");
        self.slides[newSlide].style.zIndex = self.zIndex;

        //Lifespan animation
        gsap.to(
            this.slides[oldIndex].querySelector(".js-member-name-lifespan"),
            {
                duration: self.duration / 2,
                ease: Power3.easeOut,
                delay: 0.2,
                y: "40%",
                autoAlpha: 0,
                onComplete: () => {
                    this.slides[oldIndex].classList.remove("active");
                    this.currentSlide =
                        (newIndex + this.slideCount) % this.slideCount;

                    this.isMovingToSlide = false;
                },
            }
        );

        gsap.set(
            this.slides[newSlide].querySelector(".js-member-name-lifespan"),
            {
                autoAlpha: 0,
                y: "-40%",
            }
        );

        gsap.to(
            this.slides[newSlide].querySelector(".js-member-name-lifespan"),
            {
                duration: self.duration / 2,
                ease: Power3.easeOut,
                delay: 0.2,
                autoAlpha: 1,
                y: "0%",
            }
        );

        //Title animation
        var oldDelay = 0;
        var delayCount = 0.1 * this.slideCount;
        var oldNames = this.slides[oldIndex].querySelectorAll(
            ".js-member-name-slider-name"
        );
        gsap.set(oldNames, {
            y: "0%",
            autoAlpha: 1,
        });
        forEach(oldNames, (i, name) => {
            gsap.to(name, {
                duration: self.duration / 2,
                y: "100%",
                autoAlpha: 0,
                delay: oldDelay,
                ease: Power2.easeInOut,
            });

            oldDelay = oldDelay + 0.1;

            console.log(oldDelay, delayCount);

            if (oldDelay == delayCount - 0.1) {
                this.slides[oldIndex].classList.remove("active");
                this.currentSlide =
                    (newIndex + this.slideCount) % this.slideCount;

                this.isMovingToSlide = false;
            }
        });

        var newDelay = 0;
        var newNames = this.slides[newSlide].querySelectorAll(
            ".js-member-name-slider-name"
        );
        gsap.set(newNames, {
            y: "100%",
            autoAlpha: 0,
        });
        forEach(newNames, (i, name) => {
            gsap.to(name, {
                duration: self.duration / 2,
                y: "0%",
                autoAlpha: 1,
                delay: newDelay,
                ease: Power2.easeInOut,
            });
            newDelay = newDelay + 0.1;
        });
    }

    _nextSlide() {
        this._moveToSlide(this.currentSlide, this.currentSlide + 1);
    }

    _prevSlide() {
        this._moveToSlide(this.currentSlide, this.currentSlide - 1);
    }

    _startTouch(e) {
        this.initialX = e.touches[0].clientX;
        this.initialY = e.touches[0].clientY;
    }

    _moveTouch(e) {
        if (this.initialX === null) {
            return;
        }
        if (this.initialY === null) {
            return;
        }
        const currentX = e.touches[0].clientX;
        const currentY = e.touches[0].clientY;
        const diffX = this.initialX - currentX;
        const diffY = this.initialY - currentY;

        if (Math.abs(diffX) > Math.abs(diffY)) {
            if (diffX > 0) {
                // swiped left
                this._moveToSlide(this.currentSlide, this.currentSlide + 1);
            } else {
                // swiped right
                this._moveToSlide(this.currentSlide, this.currentSlide - 1);
            }
            e.preventDefault();
        }
        this.initialX = null;
        this.initialY = null;
    }
}
