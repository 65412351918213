import { gsap, Power3 } from "gsap";

import { forEach } from "vendor/common/Helpers";

export default class ScrollAnimator {
    constructor(args = {}) {
        this.scrollbar = args.scrollbar[0] || null;
        this.elements = document.querySelectorAll(
            args.elements || "js-scroll-element"
        );

        this.pageScroll = 0;
        this.lastScrollTop = 0;

        this._initialise();
    }

    // prettier-ignore
    _initialise() {

        this._scrollbarListener();

    }

    _scrollbarListener() {
        const self = this;
        //this._detectElementsInViewport();
        // this.scrollbar.addListener(status => {
        //     this.pageScroll = status.offset.y;
        //     if (this.pageScroll > this.lastScrollTop) {
        //         self._detectElementsInViewport();
        //     }
        //     this.lastScrollTop = this.pageScroll <= 0 ? 0 : this.pageScroll;
        // });

        forEach(this.elements, (i, element) => {
            this._detectElementsInViewport(element)
        });

        this.scrollbar.addListener(status => {
            this.pageScroll = status.offset.y;
            if (this.pageScroll > this.lastScrollTop) {
                forEach(this.elements, (i, element) => {
                    this._detectElementsInViewport(element)
                });
            }
        });
    }

    _detectElementsInViewport(element) {
        if (this._isElementInViewport(element)) {
            this._setAnimationClass(element, "show");
        }
    }

    _isElementInViewport(el) {
        const box = el.getBoundingClientRect();
        return box.top < window.innerHeight && box.bottom >= 0;
    }

    _setAnimationClass(element, animationStr) {
        if (animationStr == "show") {
            if(element.classList.contains("is-hidden")){
                element.classList.remove("is-hidden");
                element.classList.add("animated");
            }
        }
    }
}
