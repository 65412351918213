// eslint-disable-next-line
import { gsap, Power1, Power2, Power3, Expo } from "gsap";
import { forEach, isMobileDevice } from "vendor/common/Helpers";

export default class MainSlider {
    constructor(args = {}) {
        this.container = args.container;
        this.slides = args.slides;
        this.slideCount = this.slides.length;
        this.currentSlide = 0;
        this.slideHeight = null;
        this.initialHeight = this.slides[0].clientHeight;

        this.sliderNavElement = args.sliderNavElement;

        this.prevBtn = args.prevBtn;
        this.nextBtn = args.nextBtn;

        this.initialX = null;
        this.initialY = null;

        this.zIndex = this.slideCount * 50;

        this.playInterval = null;

        this.duration = args.duration;

        this.mobileSlides = this.container.querySelectorAll(args.mobileSlides);

        this._initialise();

        gsap.ticker.fps(30);
        //gsap.ticker.lagSmoothing(1000, 16);
    }

    _initialise() {
        this.slides[0].classList.add("active"); //on load, activate the first slide

        forEach(this.slides, (i, slide) => {
            this.zIndex--;
            slide.style.zIndex = this.zIndex;
        });

        if (!isMobileDevice()) {
            this._playSlider();
        }

        this._initHandlers();

        if (this.mobileSlides.length == this.slideCount) {
            this._setMobileSlideHeight(this.slides[0]);
        }
    }

    _playSlider() {
        // this.playInterval = setInterval(() => {
        //     this._moveToSlide(this.currentSlide, this.currentSlide + 1);
        // }, 10000);
    }

    _initHandlers() {
        const self = this;
        if (this.sliderNavElement) {
            this.prevBtn.addEventListener(
                "click",
                this._prevSlide.bind(this),
                false
            );
            this.nextBtn.addEventListener(
                "click",
                this._nextSlide.bind(this),
                false
            );

            //Pause interval on hover
            this.sliderNavElement.addEventListener("mouseenter", function() {
                clearInterval(self.playInterval);
            });
            this.sliderNavElement.addEventListener("mouseleave", function() {
                self._playSlider();
            });
        }

        this.container.addEventListener(
            "touchstart",
            this._startTouch.bind(this),
            false
        );
        this.container.addEventListener(
            "touchmove",
            this._moveTouch.bind(this),
            false
        );

        document.body.addEventListener(
            "keydown",
            e => {
                const code = e.keyCode;
                if (code == 39) {
                    this._nextSlide();
                }
            },
            false
        );

        document.body.addEventListener(
            "keydown",
            e => {
                const code = e.keyCode;
                if (code == 37) {
                    this._prevSlide();
                }
            },
            false
        );
    }

    _moveToSlide(oldIndex, newIndex) {
        const self = this;
        const newSlide = (newIndex + this.slideCount) % this.slideCount;

        if (this.isMovingToSlide) return;
        this.isMovingToSlide = true;

        self.zIndex = self.zIndex + 3;

        this.slides[oldIndex].classList.remove("active");
        this.currentSlide = (newIndex + this.slideCount) % this.slideCount;

        this.slides[newSlide].classList.add("active");
        self.slides[newSlide].style.zIndex = self.zIndex;

        forEach(
            document.querySelectorAll(".js-main-slider-small-image"),
            (i, element) => {
                element.style.opacity = 1;
            }
        );

        forEach(
            document.querySelectorAll(".js-main-slider-large-image"),
            (i, element) => {
                element.style.opacity = 1;
            }
        );

        //Small image animation
        gsap.to(
            this.slides[oldIndex].querySelector(".js-main-slider-small-image"),
            {
                duration: self.duration,
                scale: 1.4,
                ease: Power3.easeOut,
                delay: 0.2,
                y: "-20%",
                onComplete: () => {
                    this.slides[oldIndex].classList.remove("active");
                    this.currentSlide =
                        (newIndex + this.slideCount) % this.slideCount;

                    this.isMovingToSlide = false;
                },
            }
        );

        gsap.set(
            this.slides[newSlide].querySelector(".js-main-slider-small-image"),
            {
                webkitClipPath: "inset( 100% 0 0 0)",
                scale: 1.4,
                y: "100%",
            }
        );

        gsap.to(
            this.slides[newSlide].querySelector(".js-main-slider-small-image"),
            {
                duration: self.duration,
                webkitClipPath: "inset(0% 0% 0% 0%)",
                scale: 1,
                y: "0%",
                ease: Power3.easeOut,
            }
        );

        //Large image animation
        gsap.set(
            this.slides[newSlide].querySelector(
                ".js-main-slider-large-image-overlay"
            ),
            {
                webkitClipPath: "inset(0% 100% 0% 0%)",
            }
        );

        gsap.to(
            this.slides[newSlide].querySelector(
                ".js-main-slider-large-image-overlay"
            ),
            {
                duration: self.duration / 2,
                webkitClipPath: "inset(0% 0% 0% 0%)",
                ease: Power3.easeInOut,
                delay: 0.25,
                onComplete: function() {
                    gsap.to(
                        self.slides[newSlide].querySelector(
                            ".js-main-slider-large-image-overlay"
                        ),
                        {
                            duration: self.duration / 2,
                            webkitClipPath: "inset(0% 0% 0% 100%)",
                            ease: Power2.easeOut,
                            delay: 0.2,
                        }
                    );
                },
            }
        );

        gsap.to(
            this.slides[oldIndex].querySelector(".js-main-slider-large-image"),
            {
                duration: self.duration,
                scale: 2,
                ease: Power3.easeInOut,
                x: "20%",
            }
        );

        gsap.set(
            this.slides[newSlide].querySelector(".js-main-slider-large-image"),
            {
                webkitClipPath: "inset( 0 100% 0 0)",
                scale: 1.8,
                x: "-100%",
            }
        );

        gsap.to(
            this.slides[newSlide].querySelector(".js-main-slider-large-image"),
            {
                duration: self.duration - 0.3,
                webkitClipPath: "inset(0% 0% 0% 0%)",
                scale: 1,
                x: "0%",
                ease: Power2.easeOut,
                delay: 0.3,
            }
        );

        //Title animation
        if (this.slides[oldIndex].querySelector(".js-main-slider-title")) {
            gsap.set(
                this.slides[oldIndex].querySelector(".js-main-slider-title"),
                {
                    y: "0%",
                    autoAlpha: 1,
                }
            );

            gsap.to(
                this.slides[oldIndex].querySelector(".js-main-slider-title"),
                {
                    duration: self.duration / 1.8,
                    y: "100%",
                    autoAlpha: 0,
                    delay: 0.1,
                    ease: Power3.easeInOut,
                }
            );

            gsap.set(
                this.slides[newSlide].querySelector(".js-main-slider-title"),
                {
                    y: "100%",
                    autoAlpha: 0,
                }
            );

            gsap.to(
                this.slides[newSlide].querySelector(".js-main-slider-title"),
                {
                    duration: self.duration / 1.8,
                    y: "0%",
                    autoAlpha: 1,
                    delay: 0.18,
                    ease: Power3.easeInOut,
                }
            );
        }

        //Text animation
        if (this.slides[oldIndex].querySelector(".js-events-slider-caption")) {
            gsap.set(
                this.slides[oldIndex].querySelector(
                    ".js-events-slider-caption"
                ),
                {
                    autoAlpha: 1,
                    y: "0%",
                }
            );

            gsap.to(
                this.slides[oldIndex].querySelector(
                    ".js-events-slider-caption"
                ),
                {
                    duration: self.duration / 2,
                    autoAlpha: 0,
                    y: "6%",
                    delay: 0.1,
                    ease: Power3.easeInOut,
                }
            );

            gsap.set(
                this.slides[newSlide].querySelector(
                    ".js-events-slider-caption"
                ),
                {
                    autoAlpha: 0,
                    y: "6%",
                }
            );

            gsap.to(
                this.slides[newSlide].querySelector(
                    ".js-events-slider-caption"
                ),
                {
                    duration: self.duration / 2,
                    autoAlpha: 1,
                    y: "0%",
                    delay: 0.6,
                    ease: Power3.easeInOut,
                }
            );

            if (this.mobileSlides.length == this.slideCount) {
                this._setMobileSlideHeight(this.slides[newSlide]);
            }
        }
    }

    _nextSlide() {
        this._moveToSlide(this.currentSlide, this.currentSlide + 1);
    }

    _prevSlide() {
        this._moveToSlide(this.currentSlide, this.currentSlide - 1);
    }

    _startTouch(e) {
        this.initialX = e.touches[0].clientX;
        this.initialY = e.touches[0].clientY;
    }

    _moveTouch(e) {
        if (this.initialX === null) {
            return;
        }
        if (this.initialY === null) {
            return;
        }
        const currentX = e.touches[0].clientX;
        const currentY = e.touches[0].clientY;
        const diffX = this.initialX - currentX;
        const diffY = this.initialY - currentY;

        if (Math.abs(diffX) > Math.abs(diffY)) {
            if (diffX > 0) {
                // swiped left
                this._moveToSlide(this.currentSlide, this.currentSlide + 1);
            } else {
                // swiped right
                this._moveToSlide(this.currentSlide, this.currentSlide - 1);
            }
            e.preventDefault();
        }
        this.initialX = null;
        this.initialY = null;
    }

    _setMobileSlideHeight(currentSlide) {
        if (window.innerWidth < 769) {
            gsap.to(this.container, {
                duration: 0.3,
                height: currentSlide.clientHeight,
                ease: Power3.easeInOut,
            });
        }
    }
}
