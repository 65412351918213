// eslint-disable-next-line
import LazySizes from "lazysizes";
// eslint-disable-next-line
import ObjectFit from "lazysizes/plugins/object-fit/ls.object-fit.js";
// eslint-disable-next-line
import PictureFill from "picturefill";
// eslint-disable-next-line
import Intrinsic from "picturefill/dist/plugins/intrinsic-dimension/pf.intrinsic.js";

import { tns } from "tiny-slider/src/tiny-slider";
import Splitting from "splitting";
import SimpleLightbox from "simplelightbox";
import Scrollbar, { ScrollbarPlumemberSliderTickergin } from "smooth-scrollbar";

import MainSlider from "./MainSlider";
import MemberNameSlider from "./MemberNameSlider";
import MemberPhotoSlider from "./MemberPhotoSlider";
import Menu from "./Menu";
import ScrollAnimator from "./ScrollAnimator";

import { forEach } from "vendor/common/Helpers";

// App
const app = {
    init() {
        this.scrollbar = null;

        this.initSimpleLightbox();

        this.memberSliderTicker = null;

        this.setScrollbarWidth();

        this.initSmoothScrollWheel();

        this.initMainSliders();

        this.memberSliderTicker = setInterval(() => {
            this.initMemberSliders();
        }, 100);

        this.initSupportSlider();

        this.initMenu();

        this.initNavigationScroll();

        this.initSplitting();

        this.initScrollAnimations();
    },
    setScrollbarWidth() {
        document.documentElement.style.setProperty(
            "--scrollbar-width",
            window.innerWidth - document.documentElement.clientWidth + "px"
        );
    },
    initSmoothScrollWheel() {
        this.scrollbar = Scrollbar.initAll({
            damping: 0.1,
        });
    },
    initMainSliders() {
        const slider = document.querySelectorAll(".js-main-slider");
        if (slider) {
            forEach(slider, (i, mainslider) => {
                new MainSlider({
                    container: mainslider,
                    slides: mainslider.querySelectorAll(".js-main-slider-item"),
                    sliderNavElement: mainslider.querySelector(
                        ".js-main-slider__nav"
                    ),
                    prevBtn: mainslider.querySelector(
                        ".js-slider-nav-item__prev"
                    ),
                    nextBtn: mainslider.querySelector(
                        ".js-slider-nav-item__next"
                    ),
                    duration: 1.5,
                    mobileSlides: ".js-responsive-slide",
                });
            });
        }
    },
    initMemberSliders() {
        const memberslider = document.querySelector(".js-member-slider");
        if (!memberslider.classList.contains("is-hidden")) {
            const membernameslider = document.querySelector(
                ".js-member-name-slider"
            );
            if (membernameslider) {
                new MemberNameSlider({
                    container: membernameslider,
                    slides: membernameslider.querySelectorAll(
                        ".js-member-name-slider-item"
                    ),
                    duration: 1.5,
                });
            }

            const memberphotoslider = document.querySelector(
                ".js-member-photo-slider"
            );
            if (memberphotoslider) {
                new MemberPhotoSlider({
                    container: memberphotoslider,
                    slides: memberphotoslider.querySelectorAll(
                        ".js-member-photo-slider-item"
                    ),
                    duration: 1.5,
                });
            }
            clearInterval(this.memberSliderTicker);
        }
    },
    initNavigationScroll() {
        const self = this;
        const links = document.querySelectorAll(".js-scroll-to-link");

        forEach(links, (i, link) => {
            link.addEventListener("click", function(e) {
                e.preventDefault();
                const container = document.querySelector(
                    link.getAttribute("href")
                );
                const containerOffset = offset(container);
                console.log(containerOffset.top);
                console.log(container.getBoundingClientRect().top);
                self.scrollbar[0].scrollTo(
                    1,
                    containerOffset.top + self.scrollbar[0].scrollTop,
                    2200
                );
            });
        });

        function offset(el) {
            var rect = el.getBoundingClientRect(),
                scrollLeft =
                    window.pageXOffset || document.documentElement.scrollLeft,
                scrollTop =
                    window.pageYOffset || document.documentElement.scrollTop;
            return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
        }
    },
    initMenu() {
        this.menu = new Menu({
            scrollbar: this.scrollbar
        });
    },
    initSimpleLightbox() {
        new SimpleLightbox('.gallery a', {});
    },
    initSupportSlider() {
        const slider = tns({
            container: ".js-support-slider",
            autoplay: true,
            autoplayButtonOutput: false,
            mouseDrag: true,
            nav: false,
            gutter: 130,
            speed: 800,
            preventActionWhenRunning: true,
            swipeAngle: 50,
            rewind: true,
            items: 3,
            prevButton: ".js-support-slider-prev",
            nextButton: ".js-support-slider-next",
            responsive: {
                500: {
                    items: 3,
                    gutter: 2,
                },
                768: {
                    items: 2.5,
                },
                900: {
                    items: 3.5,
                },
                1920: {
                    items: 3.5,
                },
            },
        });
    },
    initScrollAnimations() {
        this.scrollAnimator = new ScrollAnimator({
            scrollbar: this.scrollbar,
            elements: ".js-animation-in",
        });
    },
    initSplitting() {
        Splitting();

        const titles = document.querySelectorAll(".js-animating-title");

        //Split words in titles for load animations
        forEach(titles, (t, title) => {
            const words = title.querySelectorAll(".word");
            let transitionDelay = 0.1;
            forEach(words, (i, word) => {
                word.style.transitionDelay = transitionDelay + "s";
                transitionDelay = transitionDelay + 0.05;
            });
        });
    },
};

app.init();
